@import "../../assets/scss/Variables.scss";
header {
  &.navigation {
    height: 60px;
    border-bottom: 1px solid $gray-light;
    padding: 0 30px;
    position: fixed;
    width: 100%;
    z-index: 100;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    background-color: $white;
    color: $gray-dark;
    @media only screen and (max-width: 768px) {
      padding: 0px 15px;
    }
    .logo {
      position: relative;
      top: 0;
      left: 0;
      img {
        width: 106px;
        height: 26px;
      }
    }
    nav {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 60px;
        li {
          &:not(:last-child) {
            margin-right: 40px;
          }
          a {
            color: $gray-dark;
            &:hover,
            &.active {
              color: $blue-bright;
              text-decoration: none;
            }
            &.new-menu-item {
              font-weight: 500;
              &::after {
                content: "new";
                position: absolute;
                left: 80%;
                top: 5%;
                background: #10375c;
                color: $white;
                font-weight: 700;
                border-radius: 0.28571429rem;
                font-size: 0.85714286rem;
                padding: 0.3em 0.78571429em;
                line-height: 12px;
              }
            }
          }
          & > a {
            position: relative;
            &:before {
              content: "";
              width: 0;
              height: 2px;
              background: #2f55d4;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              transition: all 0.3s;
            }
            &:hover,
            &.active {
              &:before {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  & ~ .dashboard,
  & ~ .get-code {
    padding-top: 90px;
    padding-bottom: 100px;
    .content {
      h2 {
        font-size: 1.714rem;
      }
    }
  }
}
