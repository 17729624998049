@import "Variables";
body,
html,
h1,
h2,
h3,
h4,
h5,
button,
input,
optgroup,
select,
textarea,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: $gray-lightest !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
pre {
  margin-bottom: 0;
}
pre code {
  white-space: break-spaces;
}
input::-webkit-input-placeholder {
  color: $gray-medium;
}

input::-moz-placeholder {
  color: $gray-medium;
}

input:-ms-input-placeholder {
  color: $gray-medium;
}
.ui {
  &.button {
    margin: 0;
    font-weight: normal;
    // height: 2.7rem;
    box-shadow: none !important;
    .button {
      box-shadow: none !important;
    }
    &.basic {
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset !important;
      -webkit-box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset !important;
      &.blue {
        -webkit-box-shadow: 0 0 0 1px $blue-bright inset !important;
        box-shadow: 0 0 0 1px $blue-bright inset !important;
        color: $blue-bright !important;
        &:hover {
          background: $blue-bright !important;
          color: $white !important;
        }
      }
    }
  }
  &.checkbox {
    label {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
}
.ui {
  &.primary {
    &.button {
      background-color: $blue-bright;
      &:hover,
      &:focus {
        background-color: $blue-200;
      }
      .button {
        background-color: $blue-bright;
        box-shadow: none;
        &:hover,
        &:focus {
          background-color: $blue-200;
        }
      }
    }
  }
  &.icon {
    a {
      color: $white;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.form {
    input {
      &:not([type]),
      &[type="date"],
      &[type="datetime-local"],
      &[type="email"],
      &[type="file"],
      &[type="number"],
      &[type="password"],
      &[type="search"],
      &[type="tel"],
      &[type="text"],
      &[type="time"],
      &[type="url"] {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.357rem;
        &:focus {
          border-color: $blue-bright;
          border-radius: 0.357rem;
        }
      }
    }
    .field {
      & > label {
        font-weight: 500;
        font-size: 0.9rem;
        margin: 0 0 0.28571429rem 0;
      }
      & > label {
        font-weight: 500;
        font-size: 0.9rem;
        margin: 0 0 0.28571429rem 0;
      }
    }
    .inline {
      &.fields {
        & > label,
        & > p {
          font-weight: 400;
          font-size: 1rem;
        }
        .field {
          & > label,
          & > p {
            font-weight: 400;
            font-size: 1rem;
          }
          & > label {
            font-weight: 400;
            font-size: 1rem;
          }
        }
      }
    }
  }
  &.tabular {
    &.menu {
      .item {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        border: none !important;
        margin: 0;
        padding: 0.7143rem 1.42857143em;
        &.active {
          margin: 0 !important;
        }
      }
    }
  }
  &.dropdown {
    i {
      &.icon {
        color: $gray-dark;
        font-size: 0.8em;
        margin: 0 0 0 0.5rem;
        line-height: normal;
      }
    }
    .text[role="alert"] {
      color: $gray-dark;
    }
    & > .dropdown {
      &.icon {
        font-family: Icons;
        &:before {
          content: "\f078";
        }
      }
    }

    &:hover {
      i {
        &.icon {
          color: $blue-bright;
        }
      }
      .text[role="alert"] {
        color: $blue-bright;
      }
    }
  }
  &.label {
    font-weight: 400;
    font-size: 0.8rem;
  }
}
.mb-30 {
  margin-bottom: 30px;
}
footer.footer {
  height: 60px;
  background: $white;
  font-size: 16px;
  color: $black;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid $gray-light;
  i {
    &.icon {
      font-size: 14px;
    }
  }
}
.main {
  position: relative;
  min-height: 100vh;
}
.s9iconcross {
  font-size: 1.25rem;
  color: $black;
  &:hover {
    color: $blue-bright;
  }
}
a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
header {
  & ~ {
    .page-content {
      padding: 90px 0;
    }
  }
}

// Toaster
#root {
  .Toastify__toast {
    border-radius: 7px;
    &.Toastify__toast--success {
      background: #1f6650;
    }
    &.Toastify__toast--warning {
      background: #fc7e2f;
    }
    &.Toastify__toast--info {
      background: #303960;
    }
    &.Toastify__toast--danger {
      background: #9a1f40;
    }
  }
}
.s9link-table {
  border: 1px solid $gray-light;
  padding: 15px 0;
  border-radius: 0.28571429rem;
  background: $white;
  .s9link-table-row {
    padding: 0 1rem 1rem 1rem;
    &:not(:last-child) {
      margin-bottom: 1rem !important;
      border-bottom: 1px solid $gray-light;
    }
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.page-content {
  .page-title {
    border-bottom: 1px solid $gray-light;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start !important;
    }
    .title {
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
      p {
        font-size: 16px;
        color: $gray-dark;
      }
      @media only screen and (max-width: 767px) {
        margin-bottom: 1rem;
      }
    }
  }
}
.page-sub-title {
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin-bottom: 1.5rem;
}
.rs-picker-daterange {
  &.rs-picker-has-value {
    .rs-btn {
      .rs-picker-toggle-value {
        color: $black;
      }
    }
    .rs-picker-toggle {
      .rs-picker-toggle-value {
        color: $black;
      }
    }
  }
}
.ui {
  &.cards {
    & > .card {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      &:hover {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
          0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }
      & > .content {
        & > .header {
          &:not(.ui) {
            font-size: 1rem;
          }
        }
        & ~ .content {
          min-height: 80px;
        }
      }
    }
  }
}

.code-view {
  padding: 20px;
  background-color: $blue-light;
  border: 1px solid $gray-medium;
  border-radius: 3px;
  &.with-button {
    position: relative;
    padding: 40px 20px 20px 20px;
    .button {
      position: absolute !important;
      top: 0;
      right: 0;
      border-radius: 0 0 0 4px;
    }
  }
}
.paste-here {
  background: #5dbc22;
  display: block;
  padding: 5px 10px;
  color: #fff;
  margin-left: 62px;
  border-radius: 5px;
  width: max-content;
  text-align: center;
}

// Responsive CSS
@media only screen and (max-width: 768px) {
  .s9link-button {
    flex-direction: column;
    & > div {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }
}

// custom tab CSS
@media only screen and (max-width: 768px) {
  #custom-tab {
    & > div {
      .ui.grid {
        flex-direction: column;
        & > div {
          width: 100% !important;
          padding-right: 1rem !important;
          padding-left: 1rem !important;
          .vertical {
            height: auto;
            padding-bottom: 0;
            border-right: 0;
          }
        }
      }
    }
    &.installation-tab {
      & > div {
        .ui.grid {
          & > div {
            padding-right: 0 !important;
            padding-left: 0 !important;
          }
        }
      }
    }
  }
}
#configuration-action {
  @media only screen and (max-width: 768px) {
    margin: 0 !important;
    background: $white !important;
    padding: 2rem 0 1rem 0 !important;
  }
}
